<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col cols="12">
        <b-card-actions :no-actions="true" title="List Barang">
          <template v-if="cek.length > 0">
            <b-button @click.prevent="modalEditKomisi()" class="mb-1 mr-2" variant="outline-primary">Update Komisi ({{
    this.cek.length == 0 ? null : this.cek.length }})</b-button>
            <b-button @click.prevent="modalEditPoin()" class="mb-1" variant="outline-success">Update Poin ({{
    this.cek.length == 0 ? null : this.cek.length }})</b-button>
          </template>
          <template v-else>
            <b-button class="mb-1 mr-2" disabled variant="primary">Update Komisi </b-button>
            <b-button class="mb-1 mr-2" disabled variant="success">Update Poin </b-button>
          </template>
          <!-- <b-button class="mb-1 mr-2" @click.prevent="modalAturpoin()" variant="success">Atur Nilai Konversi Poin </b-button> -->
          <b-button class="mb-1" @click.prevent="modalAturtarget()" variant="success">Atur Target Komisi Bulanan dan
            Konversi Poin
          </b-button>
          <!-- Form Filter -->
          <header class="mb-1">
            <b-row>
              <!-- Page Limit -->
              <b-col md="2" sm="4" class="my-1">
                <b-form-group :label="$t('Per page')" label-size="sm"
                label-for="perPageSelect" class="mb-0" size="sm">
                  <!-- <label class="d-inline-block">Per page </label> -->
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
                </b-form-group>
              </b-col>
              <!-- / -->
              <!-- Sort By -->
              <b-col md="4" sm="6" class="my-1" v-if="!isCabang">
                <b-form-group
              label="Pilih Affiliator"
              label-for="is_member"
              class="mb-0"
            >
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="is_member"
                  v-model="is_member"
                  :options="optis_member"
                  class=""
                  @input="getData()"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
                <!-- <b-form-group :label="$t('Sort')" label-cols-sm="3" label-align-sm="right" label-size="sm"
                  label-for="sortBySelect" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-select id="sortBySelect" v-model="sortBy" size="sm" :options="sortOptions" class="w-75">
                      <template v-slot:first>
                        <option value="">-- none --</option>
                      </template>
                    </b-form-select>
                    <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                      <option :value="false">Asc</option>
                      <option :value="true">Desc</option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group> -->

              </b-col>
              <!-- / -->
              <!-- Filter Search -->
              <b-col md="6" sm="2" class="my-1">
                <b-form-group :label="$t('Filter')" label-size="sm"
                  label-for="filterInput" class="mb-0" size="sm">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Tekan enter untuk cari" size="sm"
                      @keydown.enter.prevent="getData()"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click="getData()" size="sm">
                        Cari
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- / -->
            </b-row>
          </header>
          <!-- /form filter -->
          <b-table small responsive :per-page="perPage" :items="items" :fields="fieldsOwner">
            <template #thead-top="data">
              <b-tr>
                <b-th colspan="5" style="" class="text-center">Barang</b-th>
                <!-- <b-th colspan="1" style="" class="text-center">Komisi</b-th>
                <b-th colspan="1" style="" class="text-center">Komisi</b-th> -->
                <b-th colspan="3" style="background-color: yellowgreen" class="text-center">Komisi</b-th>
                <!-- <b-th colspan="2" style="background-color: transparent"></b-th> -->
                <b-th colspan="2" style="" class="text-center">Komisi Cabang</b-th>
                <b-th colspan="3" style="background-color: yellow" class="text-center">Poin</b-th>
                <!-- <b-th colspan="3" style="background-color: transparent"></b-th> -->

                <!-- <b-th ></b-th> -->
              </b-tr>
            </template>
            <template #cell(harga_dasar)="{ item }"> Rp. {{ formatRupiah(item.harga_dasar) }} </template>
            <!-- Check box for show detail -->
            <template #cell(cek)="{ item }">
              <b-form-checkbox v-model="cek" :value="item"> </b-form-checkbox>
            </template>
            <!-- / -->
            <!-- Detail -->
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(komisi_affiliate)="{ value }">
              <b v-if="value">
                {{ value.type == "nominal" ? `${formatRupiah(value.value)}` : `${value.value}%` }}
              </b>
              <b v-else>-</b>
            </template>
            <template #cell(sisa)="{ item }">
              <b>
                {{ formatRupiah(item.share_jasa - (parseInt(item.komisi_vendor ? unFormatRupiah(item.komisi_vendor.value) : 0) +
        parseInt(item.komisi_affiliate ? unFormatRupiah(item.komisi_affiliate.value) : 0) +
        parseInt(item.komisi_cabang ? unFormatRupiah(item.komisi_cabang.value) : 0) +
        parseInt(item.komisi_bulanan ? unFormatRupiah(item.komisi_bulanan.value) : 0) +
        parseInt(item.share_jasa_cabang ? unFormatRupiah(item.share_jasa_cabang.value) : 0) +
        parseInt(item.poin ? (unFormatRupiah(item.poin.value) * (itemtarget[1] ? itemtarget[1].value : 0)) : 0) +
        parseInt(item.poin_karyawan ? (unFormatRupiah(item.poin_karyawan.value) * (itemtarget[1] ? itemtarget[1].value : 0)) : 0) +
        parseInt(item.poin_konsumen ? (unFormatRupiah(item.poin_konsumen.value) * (itemtarget[1] ? itemtarget[1].value : 0)) : 0))) }}
              </b>
            </template>
            <template #cell(share_jasa)="{ item }">
              {{ formatRupiah(item.share_jasa) }}
            </template>

            <template #cell(komisi_cabang)="{ value }">
              <b v-if="value">
                {{ value.type == "nominal" ? `${formatRupiah(value.value)}` : `${value.value}%` }}
              </b>
              <b v-else>-</b>
            </template>
            <template #cell(komisi_vendor)="{ value }">
              <b v-if="value">
                {{ value.type == "nominal" ? `${formatRupiah(value.value)}` : `${value.value}%` }}
              </b>
              <b v-else>-</b>
            </template>

            <template #cell(share_jasa_cabang)="{ value }">
              <b v-if="value">
                {{ value.type == "nominal" ? `${formatRupiah(value.value)}` : `${value.value}%` }}
              </b>
              <b v-else>-</b>
            </template>

            <template #cell(komisi_bulanan)="{ value }">
              <b v-if="value">
                {{ value.type == "nominal" ? `${formatRupiah(value.value)}` : `${value.value}%` }}
              </b>
              <b v-else>-</b>
            </template>

            <template #cell(poin)="{ value }">
              <b v-if="value">
                {{ value.value }}
              </b>
              <b v-else>-</b>
            </template>
            <template #cell(poin_karyawan)="{ value }">
              <b v-if="value">
                {{ value.value }}
              </b>
              <b v-else>-</b>
            </template>
            <template #cell(poin_konsumen)="{ value }">
              <b v-if="value">
                {{ value.value }}
              </b>
              <b v-else>-</b>
            </template>

            <template #cell(action)="{ item, index }">
              <section class="d-flex justify-content-center">
                <b-button :id="`delete-btn-${item.id}`" size="sm" @click.prevent="modalEditKomisiPoin(item)"
                  class="mr-1" variant="gradient-primary"> <!-- <feather-icon icon="TrashIcon" /> -->Update Komisi Dan
                  Poin </b-button>
                <!-- <b-tooltip :target="`delete-btn-${item.id}`">Update Komisi</b-tooltip> -->
              </section>
            </template>
          </b-table>
          <b-row>
            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
                class="my-0" />
            </b-col>
          </b-row>
          <b-modal centered size="lg" id="editKomisiPoin" title="Ubah Komisi Dan Poin" no-close-on-backdrop
            no-close-on-esc><b-row>
              <b-col md="6">
                <h4>Batas Komisi / Payout</h4>
                <hr />
                <h4>{{ formatRupiah(formKomisiPoin.share_jasa) }}</h4>
              </b-col>
              <b-col md="6">
                <h4>Total Komisi yang terhitung</h4>
                <hr />
                <h4>{{ formatRupiah(formKomisiPoin.total) }}</h4>
              </b-col>
            </b-row>
            <hr />
            <h5>Komisi Vendor</h5>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.komisi_vendor.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.komisi_vendor.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('komisi_vendor'); cekharga()"
                      v-model="formKomisiPoin.komisi_vendor.value" placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.komisi_vendor.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.komisi_vendor.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.komisi_vendor.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <h5>Komisi Affiliate</h5>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.komisi_affiliate.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.komisi_affiliate.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('komisi_affiliate'); cekharga()"
                      v-model="formKomisiPoin.komisi_affiliate.value" placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.komisi_affiliate.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.komisi_affiliate.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.komisi_affiliate.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <h5>Komisi Cabang</h5>
            <center>
              <u class="text-center">Utama</u>
            </center>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.komisi_cabang.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.komisi_cabang.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('komisi_cabang'); cekharga()"
                      v-model="formKomisiPoin.komisi_cabang.value" placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.komisi_cabang.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.komisi_cabang.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.komisi_cabang.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <center>
              <u class="text-center">Share</u>
            </center>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.share_jasa_cabang.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.share_jasa_cabang.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('share_jasa_cabang'); cekharga()"
                      v-model="formKomisiPoin.share_jasa_cabang.value" placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.share_jasa_cabang.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.share_jasa_cabang.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.share_jasa_cabang.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <h5>Komisi Bulanan</h5>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.komisi_bulanan.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.komisi_bulanan.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('komisi_bulanan'); cekharga()"
                      v-model="formKomisiPoin.komisi_bulanan.value" placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.komisi_bulanan.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.komisi_bulanan.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.komisi_bulanan.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <h5>Poin (1 Poin = {{ itemtarget[1] ? itemtarget[1].value : 0 }})</h5>
            <b-row>
              <b-col xl="4" lg="4" md="4" sm="12">
                <h5>Poin Affiliate</h5>
                <b-form-group>
                  <b-form-input v-model="formKomisiPoin.poin.value" @keyup="cekharga()"
                    placeholder="Poin"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col xl="4" lg="4" md="4" sm="12">
                <h5>Poin Karyawan</h5>
                <b-form-group>
                  <b-form-input v-model="formKomisiPoin.poin_karyawan.value" @keyup="cekharga()"
                    placeholder="Poin"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col xl="4" lg="4" md="4" sm="12">
                <h5>Poin Konsumen</h5>
                <b-form-group>
                  <b-form-input v-model="formKomisiPoin.poin_konsumen.value" @keyup="cekharga()"
                    placeholder="Poin"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <template #modal-footer>
              <b-button variant="primary" @click.prevent="simpanKomisiPoin">Simpan</b-button>
            </template>
          </b-modal>
          <b-modal centered size="lg" id="editKomisi" title="Ubah Komisi " no-close-on-backdrop no-close-on-esc>
            <b-row>
              <b-col md="12">
                <h4>Batas Komisi / Payout</h4>
                <hr />
                <h4>{{ formatRupiah(formKomisiPoin.share_jasa) }}</h4>
              </b-col>
            </b-row>
            <hr />
            <h5>Komisi Vendor</h5>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.komisi_vendor.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.komisi_vendor.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('komisi_vendor')" v-model="formKomisiPoin.komisi_vendor.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.komisi_vendor.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.komisi_vendor.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.komisi_vendor.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <h5>Komisi Affiliate</h5>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.komisi_affiliate.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.komisi_affiliate.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('komisi_affiliate')"
                      v-model="formKomisiPoin.komisi_affiliate.value" placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.komisi_affiliate.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.komisi_affiliate.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.komisi_affiliate.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <h5>Komisi Cabang</h5>
            <center>
              <u class="text-center">Utama</u>
            </center>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.komisi_cabang.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.komisi_cabang.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('komisi_cabang'); cekharga()"
                      v-model="formKomisiPoin.komisi_cabang.value" placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.komisi_cabang.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.komisi_cabang.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.komisi_cabang.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <center>
              <u class="text-center">Share</u>
            </center>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.share_jasa_cabang.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.share_jasa_cabang.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('share_jasa_cabang'); cekharga()"
                      v-model="formKomisiPoin.share_jasa_cabang.value" placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.share_jasa_cabang.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.share_jasa_cabang.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.share_jasa_cabang.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <h5>Komisi Bulanan</h5>
            <b-row>
              <b-col md="6">
                <b-form-group label="Type">
                  <b-form-select v-model="formKomisiPoin.komisi_bulanan.type" :options="[
    { value: 'nominal', text: 'Nominal' },
    { value: 'percentage', text: 'Persentase' },
  ]" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nilai">
                  <b-input-group prepend="Rp." class="input-group-merge"
                    v-if="formKomisiPoin.komisi_bulanan.type == 'nominal'">
                    <b-form-input @keyup="doFormatHarga('komisi_bulanan'); cekharga()"
                      v-model="formKomisiPoin.komisi_bulanan.value" placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                  <b-input-group class="input-group-merge"
                    v-else-if="formKomisiPoin.komisi_bulanan.type == 'percentage'">
                    <b-form-input type="number" v-model="formKomisiPoin.komisi_bulanan.value"
                      placeholder="Persentase Komisi"></b-form-input>
                    <b-input-group-append is-text>%</b-input-group-append>
                  </b-input-group>
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input disabled v-model="formKomisiPoin.komisi_bulanan.value"
                      placeholder="Nominal Komisi"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <template #modal-footer>
              <b-button variant="primary" @click.prevent="simpanKomisi">Simpan</b-button>
            </template>
          </b-modal>
          <b-modal centered size="lg" id="editPoin" title="Ubah Poin" no-close-on-backdrop no-close-on-esc>
            <h5>Poin</h5>
            <b-form-group>
              <b-form-input v-model="formKomisiPoin.poin.value" @keyup="cekharga()" placeholder="Poin"></b-form-input>
            </b-form-group>

            <template #modal-footer>
              <b-button variant="primary" @click.prevent="simpanPoin">Simpan</b-button>
            </template>
          </b-modal>
          <b-modal centered size="lg" id="aturpoin" title="Atur Poin" no-close-on-backdrop no-close-on-esc>
            <b-row>
              <b-col>
                <b-form-group>
                  <h2>Ubah Nilai Konversi Poin</h2>
                  <b-form-input size="lg" v-model="nilai_konversi" placeholder="Poin"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <h2>Nilai Konversi Poin</h2>
                  <h3>{{ itemnilaikonversi ? formatRupiah(itemnilaikonversi.nilai_konversi) : 0 }}</h3>
                </b-form-group>
              </b-col>
            </b-row>

            <template #modal-footer>
              <b-button variant="primary" @click.prevent="simpanaturPoin">Simpan</b-button>
            </template>
          </b-modal>

          <b-modal no-close-on-esc no-close-on-backdrop id="aturtarget" size="lg" ok-variant="secondary" centered
            title="Atur Target Komisi Bulanan dan Konversi Poin">
            <b-row>
              <b-col xl="12" lg="12" md="12" sm="12">
                <!-- <b-card border-variant="primary"> -->
                <!-- <hr /> -->
                <b-row>
                  <b-col xl="3" lg="3" md="3" sm="12">
                    <b-form-group label="Tipe" label-for="v-teritori">
                      <b-form-select v-model="formtarget.type" :reduce="(option) => option.value" label="text"
                        :options="opttypetarget" />
                    </b-form-group>
                  </b-col>
                  <b-col xl="3" lg="3" md="3" sm="12">
                    <b-form-group label="Nilai" label-for="v-value">
                      <b-form-input v-model="formtarget.value" />
                    </b-form-group>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="12">
                    <b-form-group label="-" label-for="v-value">
                      <b-button variant="primary" @click.prevent="submittarget" block> Simpan </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- {{itemtarget}} -->
                <b-table small :items="itemtarget" :fields="[
    { key: 'name', label: 'Nama' },
    { key: 'value', label: 'Nilai' },
  ]">
                </b-table>
                <!-- <b-button variant="danger" @click.prevent="hapuseditter" block> Lepas/Kosongkan Teritori </b-button> -->
                <!-- </b-card> -->
              </b-col>
            </b-row>
            <!-- <template #modal-footer>
        <section class="d-flex justify-content-end align-items-center">
          <b-button size="sm" variant="danger" @click="
              modalbiaya = false;
              getData();
            ">
            Keluar
          </b-button>
        </section>
      </template> -->
          </b-modal>
        </b-card-actions>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import service from "@/services";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BOverlay,
  BRow,
  BCol,
  BTr,
  BTh,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCardText,
  BTable,
  BInputGroupAppend,
  BInputGroup,
  BTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import DataBarang from "./components/DataBarang.vue";

export default {
  components: {
    DataBarang,
    BCardText,
    BOverlay,
    BButton,
    BRow,
    BCol,
    BTr,
    BTh,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    BInputGroupAppend,
    BInputGroup,
    BTooltip,
    BFormCheckbox,
  },
  data() {
    return {
      optis_member: null,
      is_member: null,
      cek: [],
      modalaturtarget: false,
      nilai_konversi: 0,
      itemnilaikonversi: null,
      formKomisiPoin: {
        barang_id: null,
        share_jasa: null,
        komisi_vendor: {
          type: null,
          value: 0,
        },
        komisi_affiliate: {
          type: null,
          value: 0,
        },
        komisi_cabang: {
          type: null,
          value: 0,
        },
        komisi_bulanan: {
          type: null,
          value: 0,
        },
        share_jasa_cabang: {
          type: null,
          value: 0,
        },
        poin: {
          type: "nominal",
          value: 0,
        },
        poin_karyawan: {
          type: "nominal",
          value: 0,
        },
        poin_konsumen: {
          type: "nominal",
          value: 0,
        },
      },
      pageOptions: [10, 20, 50],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      loaded: true,
      fields: [
        { key: "show_detail", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "kode", label: "Kode" },
        { key: "varian", label: "Varian" },
        // { key: "harga_jual", label: "Harga Jual", sortable: true },
        { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      fieldsOwner: [
        { key: "cek", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "varian", label: "Varian" },
        { key: "share_jasa", label: "Batas Komisi / Payout", sortable: true },
        { key: "komisi_vendor", label: "Vendor", sortable: true },
        { key: "komisi_affiliate", label: "Affiliate", sortable: true },
        { key: "komisi_bulanan", label: "Bulanan", sortable: true },
        { key: "komisi_cabang", label: "Utama", sortable: true },
        { key: "share_jasa_cabang", label: "Share", sortable: true },
        { key: "poin", label: "Affiliate", sortable: true },
        { key: "poin_karyawan", label: "Karyawan", sortable: true },
        { key: "poin_konsumen", label: "Konsumen", sortable: true },
        { key: "sisa", label: "Sisa Payout", sortable: true },
        // { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "Aksi" },
      ],
      loading: false,
      formtarget: {
        type: null,
        value: null,
      },
      opttypetarget: [
        { value: 'target_komisi_bulanan', text: 'Target Komisi Bulanan' },
        { value: 'point_conversion', text: 'Konversi Poin' },
      ],
      itemtarget: [],
      params: {
        order: "desc",
        // start: 0,
        // length: 10,
      },
      items: [],
      item: {},
      showMassDelButton: false,
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
        // this.items
      }
    },
  },
  methods: {
    cekharga() {
      this.formKomisiPoin.total = parseInt(this.formKomisiPoin.komisi_vendor ? this.unFormatRupiah(this.formKomisiPoin.komisi_vendor.value) : 0) +
        parseInt(this.formKomisiPoin.komisi_affiliate ? this.unFormatRupiah(this.formKomisiPoin.komisi_affiliate.value) : 0) +
        parseInt(this.formKomisiPoin.komisi_cabang ? this.unFormatRupiah(this.formKomisiPoin.komisi_cabang.value) : 0) +
        parseInt(this.formKomisiPoin.komisi_bulanan ? this.unFormatRupiah(this.formKomisiPoin.komisi_bulanan.value) : 0) +
        parseInt(this.formKomisiPoin.share_jasa_cabang ? this.unFormatRupiah(this.formKomisiPoin.share_jasa_cabang.value) : 0) +
        parseInt(this.formKomisiPoin.poin ? (this.unFormatRupiah(this.formKomisiPoin.poin.value) * (this.itemtarget[1] ? this.itemtarget[1].value : 0)) : 0) +
        parseInt(this.formKomisiPoin.poin_karyawan ? (this.unFormatRupiah(this.formKomisiPoin.poin_karyawan.value) * (this.itemtarget[1] ? this.itemtarget[1].value : 0)) : 0) +
        parseInt(this.formKomisiPoin.poin_konsumen ? (this.unFormatRupiah(this.formKomisiPoin.poin_konsumen.value) * (this.itemtarget[1] ? this.itemtarget[1].value : 0)) : 0)

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    modalEditKomisiPoin(item) {
      this.formKomisiPoin = {
        barang_id: item.id,
        share_jasa: item.share_jasa,
        komisi_vendor: item.komisi_vendor
          ? item.komisi_vendor
          : {
            type: null,
            value: 0,
          },
        komisi_affiliate: item.komisi_affiliate
          ? item.komisi_affiliate
          : {
            type: null,
            value: 0,
          },
        komisi_cabang: item.komisi_cabang
          ? item.komisi_cabang
          : {
            type: null,
            value: 0,
          },
        komisi_bulanan: item.komisi_bulanan
          ? item.komisi_bulanan
          : {
            type: null,
            value: 0,
          },
        share_jasa_cabang: item.share_jasa_cabang
          ? item.share_jasa_cabang
          : {
            type: null,
            value: 0,
          },
        poin: item.poin
          ? item.poin
          : {
            type: "nominal",
            value: 0,
          },
        poin_karyawan: item.poin_karyawan
          ? item.poin_karyawan
          : {
            type: "nominal",
            value: 0,
          },
        poin_konsumen: item.poin_konsumen
          ? item.poin_konsumen
          : {
            type: "nominal",
            value: 0,
          },
        total: (item.komisi_vendor ? item.komisi_vendor.value : 0) +
          (item.komisi_affiliate ? item.komisi_affiliate.value : 0) +
          (item.komisi_cabang ? item.komisi_cabang.value : 0) +
          (item.komisi_bulanan ? item.komisi_bulanan.value : 0) +
          (item.share_jasa_cabang ? item.share_jasa_cabang.value : 0),
      };
      this.$bvModal.show("editKomisiPoin");
    },
    modalEditKomisi(item) {
      this.$bvModal.show("editKomisi");
    },
    modalAturpoin() {
      this.$bvModal.show("aturpoin");
      this.getDatanilaipoin()
    },
    modalAturtarget() {
      this.$bvModal.show("aturtarget");
      this.getDatatargetkonversi()
    },
    submittarget() {
      let payload = {
        type: this.formtarget.type,
        value: this.formtarget.value,
      };
      this.$store
        .dispatch("settargetkonversi/save", payload)
        .then(() => {
          this.$bvModal.hide("aturtarget");
          this.displaySuccess({
            message: "Target dan Konversi berhasil disimpan",
          });
          this.getDatatargetkonversi();
        })
        .catch((e) => this.displayError(e));
    },
    modalEditPoin() {
      this.$bvModal.show("editPoin");
    },
    doFormatHarga(type) {
      if (type == "komisi_affiliate") {
        this.formKomisiPoin.komisi_affiliate.value = this.formatRupiah(this.formKomisiPoin.komisi_affiliate.value);
      }
      if (type == "komisi_cabang") {
        this.formKomisiPoin.komisi_cabang.value = this.formatRupiah(this.formKomisiPoin.komisi_cabang.value);
      }
      if (type == "share_jasa_cabang") {
        this.formKomisiPoin.share_jasa_cabang.value = this.formatRupiah(this.formKomisiPoin.share_jasa_cabang.value);
      }
      if (type == "komisi_bulanan") {
        this.formKomisiPoin.komisi_bulanan.value = this.formatRupiah(this.formKomisiPoin.komisi_bulanan.value);
      }
    },
    deleteSingle(id) {
      this.$bvModal
        .msgBoxConfirm("Anda yakin akan menghapus barang ini? Data yang didalamnya akan hilang!", {
          title: "Konfirmasi Penghapusan",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          // this.boxTwo = value
          this.$store
            .dispatch("barang/save", [
              {
                id,
                fungsi: 1,
              },
            ])
            .then(() => this.getData())
            .catch((e) => this.displayError(e));
        });
    },
    simpanKomisiPoin() {
      if (this.formKomisiPoin.total > this.formKomisiPoin.share_jasa) {
        this.displayInfo({
          message: 'Total Komisi yang terhitung melebihi Batas Komisi / Payout'
        })
        return false
      }
      let payload = this.formKomisiPoin;
      payload.komisi_vendor.value = this.unFormatRupiah(payload.komisi_vendor.value);
      payload.komisi_affiliate.value = this.unFormatRupiah(payload.komisi_affiliate.value);
      payload.komisi_bulanan.value = this.unFormatRupiah(payload.komisi_bulanan.value);
      payload.komisi_cabang.value = this.unFormatRupiah(payload.komisi_cabang.value);
      payload.share_jasa_cabang.value = this.unFormatRupiah(payload.share_jasa_cabang.value);
      payload.poin.value = this.unFormatRupiah(payload.poin.value);
      payload.poin_karyawan.value = this.unFormatRupiah(payload.poin_karyawan.value);
      payload.poin_konsumen.value = this.unFormatRupiah(payload.poin_konsumen.value);
      // console.log(payload);
      this.$store
        .dispatch("komisi/save", [payload])
        .then(() => {
          this.$bvModal.hide("editKomisiPoin");
          this.getData();
          this.displaySuccess({
            message: "Komisi berhasil diubah",
          });
          this.resetForm();
        })
        .catch((e) => this.displayError(e));
    },
    simpanKomisi() {
      let payload = [];
      this.cek.forEach((res) => {
        let payloadKomisi = {
          barang_id: res.id,
          komisi_affiliate: {
            type: this.formKomisiPoin.komisi_affiliate.type,
            value: this.unFormatRupiah(this.formKomisiPoin.komisi_affiliate.value),
          },
          komisi_cabang: {
            type: this.formKomisiPoin.komisi_cabang.type,
            value: this.unFormatRupiah(this.formKomisiPoin.komisi_cabang.value),
          },
          komisi_bulanan: {
            type: this.formKomisiPoin.komisi_bulanan.type,
            value: this.unFormatRupiah(this.formKomisiPoin.komisi_bulanan.value),
          },
          share_jasa_cabang: {
            type: this.formKomisiPoin.share_jasa_cabang.type,
            value: this.unFormatRupiah(this.formKomisiPoin.share_jasa_cabang.value),
          },
        };
        payload.push(payloadKomisi);
      });
      this.$store
        .dispatch("komisi/save", payload)
        .then(() => {
          this.$bvModal.hide("editKomisi");
          this.displaySuccess({
            message: "Komisi berhasil diubah",
          });
          this.resetForm();
          this.getData();
        })
        .catch((e) => this.displayError(e));
    },
    simpanPoin() {
      let payload = [];
      this.cek.forEach((res) => {
        let payloadKomisi = {
          barang_id: res.id,
          poin: {
            type: this.formKomisiPoin.poin.type,
            value: this.unFormatRupiah(this.formKomisiPoin.poin.value),
          },
        };
        payload.push(payloadKomisi);
      });
      this.$store
        .dispatch("komisi/save", payload)
        .then(() => {
          this.$bvModal.hide("editPoin");
          this.displaySuccess({
            message: "Poin berhasil diubah",
          });
          this.resetForm();
          this.getData();
        })
        .catch((e) => this.displayError(e));
    },

    simpanaturPoin() {
      let payload = {}

      if (this.itemnilaikonversi.length > 0) {
        payload.id = this.itemnilaikonversi[0].id
        payload.nilai_konversi = this.itemnilaikonversi[0].nilai_konversi
      }
      else {
        payload.nilai_konversi = this.nilai_konversi
      }
      this.$store
        .dispatch("nilaipoin/save", payload)
        .then(() => {
          this.$bvModal.hide("aturPoin");
          this.nilai_konversi = null;
          this.getDatanilaipoin()
          this.displaySuccess({
            message: "Poin berhasil disimpan",
          });
        })
        .catch((e) => this.displayError(e));
    },
    resetForm() {
      (this.cek = []),
        (this.formKomisiPoin = {
          barang_id: null,
          komisi_vendor: {
            type: null,
            value: 0,
          },
          komisi_affiliate: {
            type: null,
            value: 0,
          },
          komisi_cabang: {
            type: null,
            value: 0,
          },
          komisi_bulanan: {
            type: null,
            value: 0,
          },
          share_jasa_cabang: {
            type: null,
            value: 0,
          },
          poin: {
            type: "nominal",
            value: 0,
          },
          poin_karyawan: {
            type: "nominal",
            value: 0,
          },
          poin_konsumen: {
            type: "nominal",
            value: 0,
          },
        });
    },
    async clearExportedFile(filename) {
      try {
        const config = {
          url: `/clear-export`,
          method: "get",
          params: {
            filename,
          },
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async print() {
      try {
        const config = {
          url: `/excel-data-barang`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return this.customRejectPromise(e);
      }
    },
    async exportBarang() {
      try {
        this.loading = true;

        const response = await this.print();
        await window.open(response);
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split("/");
          const filename = arrFile[arrFile.length - 1];

          await this.clearExportedFile(filename);
        }, 1000);

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    updatePage(val) {
      this.currentPage = val;
      this.getData();
    },
    getDatatargetkonversi() {
      const payload = {
        // type: this.formtarget.type,
        // value: this.formtarget.value,
      };
      this.$store
        .dispatch("settargetkonversi/getData", payload)
        .then((response) => {
          this.itemtarget = response;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getDatanilaipoin() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
      };
      this.$store
        .dispatch("nilaipoin/getData", payload)
        .then((response) => {
          // let items = JSON.parse(JSON.stringify(this.$store.state.nilaipoin));
          // let items_total = this.$store.state.nilaipoin.totals;
          this.itemnilaikonversi = response;
        })
        .catch((e) => {
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    getData() {
      // if (this.items > 1)
      // this.loading = true;
      // this.loaded = false;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        member_id: this.is_member != null ? this.is_member : null,
        // filter_by: "nama_lengkap",
      };
      this.$store
        .dispatch("komisi/getData", payload)
        .then((response) => {
          // this.items = null
          // console.log('HEMMM',this.items)

          let items = JSON.parse(JSON.stringify(this.$store.state.komisi.datas));
          let items_total = this.$store.state.komisi.totals;
          this.items = items;
          this.totalRows = items_total;
          // this.$refs.table.refresh()
          // console.log('HEMMM',this.items)
          // this.$root.$emit("bv::refresh::table", "table");
          // this.loaded = true;
          // this.loading = false;
        })
        .catch((e) => {
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    onItemChecked(payload) {
      if (payload.selectedRows.length > 0) {
        this.showMassDelButton = true;
      } else {
        this.showMassDelButton = false;
      }
    },
  },
  computed: {
    rows() {
      return this.items.total;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    getMember() {
      // const perPage = parseInt(this.perPage);
      // const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        sebagai: 'cabang'
      };
      this.$store.dispatch("member/getData", payload).then(() => {
        let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        items.map((item) => {
          item.value = item.id;
          item.text = item.nama_lengkap;
        });
        this.optis_member = items;
      });
    },
  },
  mounted() {
    // this.getData();
  },
  created() {
    this.getData();
    this.getDatatargetkonversi()
    this.getMember()
    // this.rows = barang.list;
  },
};
</script>
